import React from 'react'
import {PrismicRichText, PrismicText} from '@prismicio/react'
import {PrismicNextImage} from "@prismicio/next";

/**
 * @typedef {import("@prismicio/client").Content.ExperienceSlice} ExperienceSlice
 * @typedef {import("@prismicio/react").SliceComponentProps<ExperienceSlice>} ExperienceProps
 * @param { ExperienceProps }
 */
const Experience = ({ slice }) => {
  const calculateDateDifference = (startDate, endDate) => {
    const diff = new Date(
      endDate.getFullYear() - startDate.getFullYear(),
      endDate.getMonth() - startDate.getMonth(),
      endDate.getDate() - startDate.getDate()
    );

    return {
      years: diff.getYear(),
      months: diff.getMonth(),
      days: diff.getDate()
    }
  }

  return (
    <section className="py-10 dark:bg-gray-600 dark:text-gray-200">
      <div className="container">
        <h2 className="text-3xl text-center">
          <PrismicText field={slice.primary.title}/>
        </h2>
        <PrismicRichText field={slice.primary.description}/>

        {slice.items.length > 0 && (
          <div className="container mt-4">
            <div className="relative max-w-4xl mx-auto w-full">
              <div className="absolute z-10 h-full ml-10 border border-dashed border-gray-300 dark:border-gray-700"></div>
              <div className="relative z-20">
                {slice.items.map((item, index) => {
                  const startDate = new Date(item.start_date),
                    endDate = item.end_date ? new Date(item.end_date) : new Date(),
                    difference = calculateDateDifference(startDate, endDate),
                    dateOptions = { year: 'numeric', month: 'short' };

                  return (
                    <div key={index} className="flex space-x-4 py-4">
                      <div className="flex-shrink-0 w-20">
                        <PrismicNextImage
                          field={item.logo}
                          className="object-contain object-center w-16 h-16 ml-2"
                        />
                      </div>
                      <div>
                        <div className="font-bold">{item.title}</div>
                        <div className="flex">
                          <div className="text-gray-500 dark:text-gray-400">
                            {item.function} -{' '}
                            {new Intl.DateTimeFormat('en-US', dateOptions).format(startDate)} -{' '}
                            {item.end_date ? new Intl.DateTimeFormat('en-US', dateOptions).format(endDate) : 'Present'}{' '}
                            (
                              {difference.years > 0 ? `${difference.years} ${difference.years === 1 ? 'yr' : 'yrs'}` : ''}
                              {difference.years > 0 && difference.months > 0 ? ' ' : ''}
                              {difference.months > 0 ? `${difference.months} ${difference.months === 1 ? 'mo' : 'mos'}` : ''}
                            )
                          </div>
                        </div>
                        <div className="mt-2 prose max-w-full dark:text-gray-200">
                          <PrismicRichText field={item.description}/>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  )
}

export default Experience
