import React, {useEffect, useState} from 'react'
import {Field, Formik, Form as FormikForm, useFormik} from "formik";
import {CheckCircleIcon, CheckIcon, XCircleIcon, XMarkIcon} from "@heroicons/react/24/outline";
import {PrismicRichText, PrismicText} from "@prismicio/react";
import {trackFormSubmitEvent} from "../../utils";

/**
 * @typedef {import("@prismicio/client").Content.FormSlice} FormSlice
 * @typedef {import("@prismicio/react").SliceComponentProps<FormSlice>} FormProps
 * @param { FormProps }
 */
const Form = ({ slice }) => {
  const [submitted, setSubmitted] = useState(false),
    [error, setError] = useState(null),
    [success, setSuccess] = useState(false);

  const getIdentifier = (key) => key.toLowerCase().replace(' ', '');

  const initialValues = slice.items.reduce((carry, item) => {
    carry[getIdentifier(item.name)] = '';

    return carry;
  }, {});

  const handleSubmit = async (values) => {
    setSubmitted(true);

    const data = {
      recipient: {
        name: slice.primary.recipient,
        email: slice.primary.email_address
      },
      subject: slice.primary.email_subject,
      data: slice.items.filter((item) => item.type.toLowerCase() !== 'button')
        .reduce((carry, item) => {
          const identifier = getIdentifier(item.name);

          carry[identifier] = {
            key: item.name,
            value: values[identifier]
          };

          return carry;
        }, {})
      };

    await fetch('/api/form', {
      method: 'POST',
      body: JSON.stringify(data)
    })
      .then((response) => {
        trackFormSubmitEvent(
          slice.primary.email_subject,
          data
        );

        setSubmitted(false);
        setSuccess(true);
        setError(false);
      })
      .catch((error) => {
        setSubmitted(false);
        setError(true);
        setSuccess(false);
      });
  }

  return (
    <section className="py-10 overflow-hidden bg-white flex flex-col gap-8 dark:bg-gray-600 dark:text-gray-200">
      <div className="container">
        {slice.primary.title ? (
          <h2 className="text-4xl mb-4 text-center"><PrismicText field={slice.primary.title}/></h2>
        ) : ''}

        {slice.primary.description ? (
          <div className="text-center md:w-2/3 mx-auto">
            <PrismicRichText field={slice.primary.description}/>
          </div>
        ) : ''}
      </div>
      <div className="max-w-xl mx-auto w-full px-4 lg:px-0">
        {error && (
          <div className="rounded-md bg-red-300 py-3 px-4 flex gap-2 mb-4">
            <XCircleIcon className="w-6" />
            {slice.primary.error_message}
          </div>
        )}
        {success && (
          <div className="rounded-md bg-green-300 py-3 px-4 flex gap-2 mb-4">
            <CheckCircleIcon className="w-6"/>
            {slice.primary.success_message}
          </div>
        )}
        {!success && (
          <Formik
            initialValues={initialValues}
            onSubmit={(values) => handleSubmit(values)}
          >
            {({ handleChange, values}) => (
              <FormikForm className="grid grid-cols-1 gap-y-6">
                {slice.items.map((item, index) => {
                  const identifier = getIdentifier(item.name),
                    fieldType = item.type.toLowerCase();

                  return (
                    <div key={index}>
                      {fieldType !== 'button' ? (
                        <div className="flex justify-between items-center">
                          <label htmlFor={identifier} className="block font-medium text-gray-700 dark:text-gray-200">
                            {item.name}
                          </label>
                          {!item.required ? (
                            <div className="text-sm text-gray-500 dark:text-gray-300">Optional</div>
                          ) : ''}
                        </div>
                      ) : ''}
                      <div className="mt-1">
                        {fieldType === 'button' ? (
                          <button
                            type="submit"
                            className="btn btn-blue-invert w-full dark:btn-orange-invert"
                            disabled={submitted}
                          >
                            {item.name}
                          </button> ) : (
                            <Field
                              type={fieldType || 'text'}
                              as={fieldType === 'textarea' ? 'textarea' : null}
                              name={identifier}
                              value={values[identifier]}
                              required={item.required}
                              placeholder={item.placeholder}
                              onChange={handleChange(identifier)}
                              className={`block w-full rounded-md border border-gray-300 px-4 py-3 shadow-sm focus:border-blue-light ${fieldType === 'textarea' ? 'h-40' : ''}
                                dark:bg-gray-800 dark:border-gray-200 dark:text-gray-200`}
                            />
                          )
                        }
                      </div>
                      {item.note ? (
                        <div className="text-xs text-gray-500 mt-1">{item.note}</div>
                      ) : ''}
                    </div>
                  )
                })}
              </FormikForm>
            )}
          </Formik>
        )}
      </div>
    </section>
  )
}

export default Form
