import React from 'react'

import { PrismicNextImage } from "@prismicio/next";
import { PrismicLink, PrismicRichText, PrismicText } from '@prismicio/react'
import { ChevronRightIcon } from "@heroicons/react/24/outline";

/**
 * @typedef {import("@prismicio/client").Content.ContentGridSlice} ContentGridSlice
 * @typedef {import("@prismicio/react").SliceComponentProps<ContentGridSlice>} ContentGridProps
 * @param { ContentGridProps }
 */
const ContentGrid = ({slice}) => {
  const ItemWrapper = ({item, className, children}) => (
    item?.link.id || item?.link.url
      ? <PrismicLink field={item.link} className={className} title={item.title}>{children}</PrismicLink>
      : <div className={className}>{children}</div>
  );

  return (
    <section className="bg-gray-50 dark:bg-gray-900 py-16">
      <div className="container">
        <div className="mx-auto max-w-7xl">
          <div className="text-center">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 dark:text-gray-50 sm:text-4xl">
              <PrismicText field={slice.primary.title}/>
            </h2>
            <p className="mx-auto mt-3 max-w-2xl text-xl text-gray-500 dark:text-gray-150 sm:mt-4">
              <PrismicRichText field={slice.primary.content}/>
            </p>
          </div>
        </div>
        <div className="mx-auto grid max-w-lg gap-5 lg:max-w-none lg:grid-cols-3">
          {slice.items.map((item, index) => (
            <div key={index} className={`bg-white dark:bg-gray-900 transition duration-500 overflow-hidden rounded-lg shadow-lg ${item.link.url || item.link.id ? `hover:shadow-gray-300 group`: ``}`}>
              <ItemWrapper item={item} className="flex flex-col h-full">
                <div className="flex-shrink-0">
                  <PrismicNextImage
                    field={item.image}
                    className="h-60 w-full object-cover object-center transition duration-500 group-hover:opacity-75"
                  />
                </div>
                <div className="flex flex-1 flex-col justify-between bg-white dark:bg-gray-700 p-6 h-full">
                  <div className="flex-1 mb-2">
                    <div className="mt-2 block">
                      <p className="text-xl font-semibold text-gray-700 dark:text-gray-400">
                        {item.title}
                      </p>
                      <div className="mt-3 text-base text-gray-500 dark:text-white prose">
                        <PrismicRichText field={item.content}/>
                      </div>
                    </div>
                  </div>
                  {item.link_text && (item?.link.id || item?.link.url)  ? (
                    <div className="font-medium text-blue-darker dark:text-gray-400 mt-auto">
                      <span className="group-hover:underline flex items-center">
                        {item.link_text} <ChevronRightIcon className="w-4 ml-1"/>
                      </span>
                    </div>
                  ) : ''}
                </div>
              </ItemWrapper>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default ContentGrid
