import React from "react";
import { PrismicNextImage } from "@prismicio/next";
import { PrismicLink, PrismicRichText, PrismicText } from "@prismicio/react";

const SplitHeroBanner = ({ slice }) => (
  <section className="relative bg-gray-200 dark:bg-gray-800">
    <div className="container max-w-7xl">
      <div className="relative z-10 lg:w-full lg:max-w-2xl">
        <svg
          className="absolute inset-y-0 right-8 hidden h-full w-80 translate-x-1/2 transform fill-gray-200 dark:fill-gray-800 lg:block"
          viewBox="0 0 100 100"
          preserveAspectRatio="none"
          aria-hidden="true"
        >
          <polygon points="0,0 90,0 50,100 0,100" />
        </svg>

        <div className="relative py-32 px-6 sm:py-40 lg:py-56 lg:px-8 lg:pr-0">
          <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-xl">
            <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl dark:text-gray-200">
              <PrismicText field={slice.primary.title} />
            </h1>
            {slice.primary.subtitle.length ? (
              <div className="text-2xl font-medium mt-1 dark:text-gray-400">
                <PrismicText field={slice.primary.subtitle}/>
              </div>
            ) : ''}
            <div className="mt-6 text-lg prose max-w-full leading-8 text-gray-600 dark:text-gray-200">
              <PrismicRichText field={slice.primary.content}/>
            </div>
            {slice.primary.button_text && slice.primary.button_link ? (
              <div className="mt-10 flex items-center gap-x-6">
                <PrismicLink
                  field={slice.primary.button_link}
                  className="btn btn-blue dark:btn-white"
                >
                  {slice.primary.button_text}
                </PrismicLink>
              </div>
            ) : ''}
          </div>
        </div>
      </div>
    </div>
    <div className="bg-gray-50 hidden lg:block lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 overflow-hidden">
      <PrismicNextImage
        field={slice.primary.image}
        className="transform aspect-[3/2] object-cover lg:aspect-auto lg:h-full lg:w-full"
      />
    </div>
  </section>
)

export default SplitHeroBanner;
