import React from 'react'
import Link from "next/link";
import {PrismicLink, PrismicText} from "@prismicio/react";

/**
 * @typedef {import("@prismicio/client").Content.BreadcrumbsSlice} BreadcrumbsSlice
 * @typedef {import("@prismicio/react").SliceComponentProps<BreadcrumbsSlice>} BreadcrumbsProps
 * @param { BreadcrumbsProps }
 */
const Breadcrumbs = ({ slice, context }) => {
  const { page } = context;

  return (
    <section className="bg-blue-light dark:bg-gray-600 dark:text-gray-200 py-3">
      <div className="container">
        <ul className="flex gap-2">
          <li>
            <Link href="/">Home</Link>
          </li>
          <li>/</li>
          {slice.primary.parent_document?.id ? (
            <>
              <li>
                <PrismicLink field={slice.primary.parent_document}>
                  <PrismicText field={slice.primary.parent_document.data.title}/>
                </PrismicLink>
              </li>
              <li>/</li>
            </>
          ) : ''}
          <li>
            <PrismicText field={page?.data?.title}/>
          </li>
        </ul>
      </div>
    </section>
  )
}

export default Breadcrumbs
