import React from 'react'
import { PrismicNextImage } from "@prismicio/next";
import { PrismicLink, PrismicRichText, PrismicText } from "@prismicio/react";
import SplitHeroBanner from "./SplitHeroBanner";

/**
 * @typedef {import("@prismicio/client").Content.HeroBannerSlice} HeroBannerSlice
 * @typedef {import("@prismicio/react").SliceComponentProps<HeroBannerSlice>} HeroBannerProps
 * @param { HeroBannerProps }
 */
const HeroBanner = ({ slice }) => (
  <>
    {slice.variation === 'splitImage' ? (
      <SplitHeroBanner slice={slice} />
    ) : (
      <section className="relative h-[40rem] flex flex-row items-center overflow-hidden">
        <PrismicNextImage
          field={slice.primary.image}
          className="block blur-sm transform object-cover object-top absolute inset-0 w-full h-full"
        />
        {slice.primary.title &&
          <div className="container relative">
            <div
              className="p-3 text-white text-center">
              <h1 className="text-4xl mb-4"><PrismicText field={slice.primary.title}/></h1>
              <div className="text-lg lg:w-2/3 text-center mx-auto">
                <PrismicRichText field={slice.primary.content}/>
              </div>
              {
                slice.primary.button_text && slice.primary.button_link && <div className="my-12">
                  <PrismicLink
                    field={slice.primary.button_link}
                    className="btn btn-transparent"
                  >
                    {slice.primary.button_text}
                  </PrismicLink>
                </div>
              }
            </div>
          </div>
        }
      </section>
    )}
  </>
)

export default HeroBanner
