import React from 'react'
import { PrismicLink, PrismicRichText, PrismicText } from '@prismicio/react'

/**
 * @typedef {import("@prismicio/client").Content.TextBlockSlice} TextBlockSlice
 * @typedef {import("@prismicio/react").SliceComponentProps<TextBlockSlice>} TextBlockProps
 * @param { TextBlockProps }
 */
const TextBlock = ({ slice }) => {
  return (
    <section className="py-10 dark:bg-gray-800">
      <div className="container">
        <div className="text-center lg:w-2/3 mx-auto">
          {slice.primary.title.length > 0 ? (
            <div className="text-4xl mb-4 dark:text-gray-400">
              <PrismicText field={slice.primary.title}/>
            </div>
          ) : ''}

          <div className="prose max-w-full dark:text-white">
            <PrismicRichText field={slice.primary.content}/>
          </div>
        </div>

        {slice.items.length > 0 && (
          <div className="flex flex-col lg:flex-row justify-center items-center gap-8 mt-4">
            {slice.items.map((item, index) => {
              const style = item?.style !== null
                ? `btn-${item.style.toLowerCase().replace(' ', '-')}`
                : 'btn-blue-invert dark:btn-orange';

              return (
                <div key={index}>
                  <PrismicLink
                    className={`btn ${style}`}
                    field={item.link}
                  >
                    {item.label}
                  </PrismicLink>
                </div>
              )
            })}
          </div>
        )}
      </div>
    </section>
  )
}

export default TextBlock
