import React from 'react'
import { PrismicLink, PrismicText, PrismicRichText } from '@prismicio/react'
import { PrismicNextImage } from "@prismicio/next";
import {QuestionMarkCircleIcon} from "@heroicons/react/24/outline";
import Link from "next/link";

/**
 * @typedef {import("@prismicio/client").Content.CasesSlice} CasesSlice
 * @typedef {import("@prismicio/react").SliceComponentProps<CasesSlice>} CasesProps
 * @param { CasesProps }
 */
const Cases = ({ slice }) => (
  <section className="bg-white py-16">
    <div className="container">
      {slice.primary.title.length ? (
        <h2 className="text-center text-4xl container mb-10">
          <PrismicText field={slice.primary.title}/>
        </h2>
      ) : ''}
      <div>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-5">
          {slice.items.map((item, index) => (
            <div key={index} className="relative h-72 overflow-hidden group rounded-lg shadow-lg">
              <div className="h-full w-full relative overflow-hidden rounded-lg transform duration-500 group-hover:scale-110">
                <PrismicNextImage
                  field={item?.case?.data?.image}
                  className="absolute inset-0 w-full h-full object-cover object-center"
                />
              </div>
              <PrismicLink
                field={item.case}
                className="absolute inset-0 text-white bg-gray-800 opacity-80 group-hover:bg-opacity-50 absolute inset-0 transition transform duration-500 flex justify-center items-center"
              >
              <span className="opacity-0 group-hover:opacity-100 transition transform duration-500 block flex gap-2 flex-col justify-center items-center">
                <span className="text-lg"><PrismicText field={item?.case.data.title}/></span>
                <span className="italic -mt-3 mb-3">{item?.case.data.type}</span>
                <div className="btn btn-transparent py-2 px-4 text-base tracking-wider">
                  View Project
                </div>
              </span>
              </PrismicLink>
            </div>
          ))}

          {slice.primary.show_teaser ? (
            <div className="relative h-72 overflow-hidden group rounded-lg shadow-lg">
              <div className="h-full w-full relative overflow-hidden bg-gray-700 rounded-lg transform duration-500 group-hover:scale-110 flex items-center justify-center">
                <QuestionMarkCircleIcon className="w-80 h-80 text-gray-300"/>
              </div>
              <PrismicLink
                field={slice.primary.teaser_link}
                className="absolute inset-0 text-white bg-gray-800 opacity-80 group-hover:bg-opacity-50 absolute inset-0 transition transform duration-500 flex justify-center items-center"
              >
                <span className="opacity-0 group-hover:opacity-100 transition transform duration-500 block flex gap-2 flex-col justify-center items-center">
                  <span className="text-lg">{slice.primary.teaser_title}</span>
                  <span className="italic -mt-3 mb-3">{slice.primary.teaser_subtitle}</span>
                  <div className="btn btn-transparent py-2 px-4 text-base tracking-wider">
                    {slice.primary.teaser_button_text}
                  </div>
                </span>
              </PrismicLink>
            </div>) : ''}
        </div>
      </div>
      {(slice.primary.description.length || slice.primary.link.id) && (
        <div className="container">
          <div className="text-center px-4 mt-10 prose max-w-full">
            <PrismicRichText field={slice.primary.description}/>
          </div>
          {slice.primary.link.id && (
            <div className="text-center mt-4">
              <PrismicLink field={slice.primary.link} className="btn btn-blue">
                {slice.primary.link_label || `View All Cases`}
              </PrismicLink>
            </div>
          )}
        </div>
      )}
    </div>
  </section>
)

export default Cases
