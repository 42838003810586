// Code generated by Slice Machine. DO NOT EDIT.

import Breadcrumbs from './Breadcrumbs';
import Cases from './Cases';
import ContentGrid from './ContentGrid';
import Experience from './Experience';
import Form from './Form';
import HeroBanner from './HeroBanner';
import TextBlock from './TextBlock';

export {
	Breadcrumbs,
	Cases,
	ContentGrid,
	Experience,
	Form,
	HeroBanner,
	TextBlock,
};

export const components = {
	breadcrumbs: Breadcrumbs,
	cases: Cases,
	content_grid: ContentGrid,
	experience: Experience,
	form: Form,
	hero_banner: HeroBanner,
	text_block: TextBlock,
};
